@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

@import '../../../styles/ant-theme.less';
@import '../../../styles/typography.less';
@import '../../../styles/utility.less';

@import '../../../styles/ant-overrides.less';
@import '../../../styles/feather-overrides.less';
@import '../../../styles/hubspot-overrides.less';
@import '../../../styles/quill-overrides.less';
@import '../../../styles/tailwind/tailwind-output-onboarding.css';

@import '@angular/cdk/overlay-prebuilt.css';

// Theme overrides
@body-background: @sand-200;

.onboarding-body {
  background-color: @body-background;
}
